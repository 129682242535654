<template>
  <div>
    <b-card no-body>
      <HTMLTitle :title="$t('accounts.add_meli_account.page_title')"></HTMLTitle>
      <card-header :title="$t('accounts.add_meli_account.page_title')" :icon="['fas', 'plus']"
        :description="$t('accounts.add_meli_account.description')"></card-header>
    </b-card>

    <b-overlay :show="formSubmitted" :spinner-variant="colorVariant('', 'primary')" variant="dark" opacity="0.85" spinner-type="grow" spinner rounded="sm">
      <form-wizard color="#4AA3DF" :title="null" :subtitle="null" shape="circle" :finish-button-text="$t('accounts.add_meli_account.submit')"
        :back-button-text="$t('accounts.add_meli_account.back')" :nextButtonText="$t('accounts.add_meli_account.next')" class="mb-3" @on-complete="submitForm">

        <!-- generate code tab -->
        <tab-content :title="$t('accounts.add_meli_account.generate_code_tab_title')">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t('accounts.add_meli_account.generate_code_title') }}
              </h5>
            </b-col>
            <b-col md="12">
              <!-- <b-link :href="$t('accounts.add_meli_account.generate_code_link')" target="_blank">{{ $t('accounts.add_meli_account.generate_code_link_text') }}</b-link>-->
              <b-button type="button" variant="outline-primary" :href="$t('accounts.add_meli_account.generate_code_link')" target="_blank">
                <feather-icon size="16" icon="LinkIcon" />
                <span class="align-middle">&nbsp;{{ $t('accounts.add_meli_account.generate_code_link_text') }}</span>
              </b-button>
              <br/><br/>
              <small v-html="$t('accounts.add_meli_account.generate_code_description')"></small>
            </b-col>
          </b-row>
        </tab-content>

        <!-- validate code tab -->
        <tab-content :title="$t('accounts.add_meli_account.validate_code_tab_title')">
          <validation-observer ref="validateForm" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t('accounts.add_meli_account.validate_code_title') }}
                </h5>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="first-name">
                  <validation-provider #default="{ errors }" :name="$t('accounts.add_meli_account.validate_code_code')" rules="required|min:8">
                    <b-form-input id="first-name" v-model="associationCode" :placeholder="$t('accounts.add_meli_account.validate_code_code_description')"
                      :state="errors.length > 0 ? false:null" maxlength="8"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import Crypt from '@core-md/utils/crypt'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import authorizationsClient from '@core-md/services/authorizations/client'
import CardHeader from '@core-md/components/cards/CardHeader.vue'

export default {
  components: {
    HTMLTitle,
    BCard,
    BOverlay,
    BButton,
    CardHeader,
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
  ],
  data() {
    return {
      form: {
        associationCode: '',
      },
      associationCode: '',
      formSubmitted: false,
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  watch: {
    associationCode(val) {
      if (val.length === 8) {
        this.form.associationCode = val
        this.submitForm()
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.validateForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const token = Crypt.signToken(this.form)
          authorizationsClient.validateCodeMeli({
            request: token,
          })
            .then(response => {
              const result = Crypt.decrypt(response.data.result)

              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(this.$i18n.t(result.statusMessageDetail || result.statusMessage), this.$i18n.t('common.messages.success'))
                authorizationsClient.setCachedAuthorizations([])
                this.$store.dispatch('authorizations/initialize')
                this.$store.dispatch('authorizations/refreshAuthorizations')
                this.$router.push({ name: 'accounts' })
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.error'))
              }
              this.formSubmitted = false
            })
            .catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'common.messages.error')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
